<template>
    <div :class="{ 'overlay': isLoading }">
        <div>
            <h5>Chỉnh sửa tin tức mới</h5>
            <form class="wp-formAdd" @submit="editNews(dataDetail.news_id)">
                <div class="d-flex align-items-center justify-content-between">
                    <p style="font-size: 20px; font-weight: 500;">Viết tin tức</p>
                    <div class="d-flex">
                        <span style="margin-right: 20px;">Gửi thông báo đến tất cả khách hàng:</span>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" v-model="dataDetail.noti_status">
                        </div>
                        <button class="btn-del" type="button" @click="delNews(dataDetail.news_id)"
                            v-if="intListRole.includes(41)">Xóa tin tức</button>
                    </div>
                </div>
                <b-row class="align-items-center mb-3">
                    <b-col cols="md-2">
                        <p>Hình ảnh hiển thị</p>
                    </b-col>
                    <b-col cols="md-10">
                        <img :src="this.dataImg" v-if="this.dataImg"
                            style="max-width: 177px; border-radius: 10px; margin-right: 20px;"
                            onclick="$('#file_img').click()" @error="handleError">
                        <img src="@/assets/admin/icon/icon_add.png" alt="" onclick="$('#file_img').click()" v-else>
                        <b-form-file class="mt-3 d-none" id="file_img" accept="image/png, image/gif, image/jpeg, image/jpg"
                            ref="fileInput" @change="handleImgChange"></b-form-file>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="md-2">
                        <label for="ttl">Tiêu đề tin tức</label>
                    </b-col>
                    <b-col cols="md-10">
                        <b-input type="text" id="ttl" placeholder="Nhập tiêu đề của tin tức và thông báo"
                            v-model="dataDetail.title" @input="formatTxt('title')"></b-input>
                    </b-col>
                </b-row>

                <p class="mt-4" style="font-size: 20px; font-weight: 600;">Nội dung tin tức và thông báo</p>

                <ckeditor :editor="editor" v-model="dataDetail.description" :config="editorConfig" @ready="onReady">
                </ckeditor>

                <div class="text-right">
                    <button @click="$router.go(-1)" type="button" :disabled="isLoading">Hủy bỏ</button>
                    <button class="send" type="submit" v-if="intListRole.includes(40)" :disabled="isLoading">Chỉnh sửa bài
                        viết</button>
                </div>
            </form>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-classic/build/translations/vi';
import axios from 'axios'
export default {
    data() {
        function uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return uploadImage(loader);
            };
        }

        function uploadImage(loader) {
            return {
                upload: () => {
                    return new Promise((resolve, reject) => {
                        loader.file.then(async (file) => {
                            try {
                                if (!file) return

                                var data = {
                                    'file': file,
                                    'path': 'news/image/'
                                }

                                await axios.post('https://api.daugiadgv.vn/api/common/uploadFile', data, {
                                    headers: {
                                        'x-access-token': '',
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(res => {
                                    const url = 'https://api.daugiadgv.vn/storage/' + res.data.list_result[0].path_tam
                                    resolve({ default: url })
                                })
                            } catch (error) {
                                reject(error)
                            }
                        })
                    })
                }
            }
        }
        return {
            dataImg: '',
            token: '',
            dataDetail: [],
            dataUpdate: {
                news_id: '',
                title: '',
                description: '',
                type: '',
                image: '',
                noti_status: '',
                publish_status: '',
            },
            listRole: [],
            intListRole: [],
            isLoading: false,
            editor: Editor,
            editorConfig: {
                extraPlugins: [uploader],
                language: 'vi',
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'strikethrough',
                        'underline',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageUpload',
                        'link',
                        '|',
                        'blockQuote',
                        'insertTable',
                        'alignment',
                        '|',
                        'fontFamily',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                    ]
                },
            }
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
            info.roles.arr_resource.forEach(element => {
                this.listRole.push(element.resource_id)
            });
            this.intListRole = this.listRole.map(Number)
        }
        this.getNewsDetail()
    },
    methods: {
        async uploadImage(type, file) {
            const formData = {
                'file': file,
                'path': 'news/image/'
            };
            await this.$axios.post('/common/uploadFile', formData, {
                headers: {
                    'x-x-access-token': this.token,
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                if (type == 'thumb') {
                    this.dataUpdate.image = response.data.list_result[0].path

                }
            })
        },
        handleImgChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.dataImg = reader.result
                    this.uploadImage('thumb', file)
                };
                reader.readAsDataURL(file);
            }
        },
        async getNewsDetail() {
            await this.$axios.get('/news/getNewsDetailForAdmin', {
                params: {
                    news_id: this.decode(this.$route.params.news_id)
                },
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.dataDetail = res.data
                this.dataImg = this.$storage + res.data.image
                this.dataUpdate.title = res.data.title
                this.dataUpdate.description = res.data.description
                this.dataUpdate.image = res.data.image
                this.dataUpdate.noti_status = res.data.noti_status
            }).catch(err => {
                if (err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        async delNews(id) {
            const data = {
                "news_id": id
            }
            await this.$axios.put('/news/deleteNews', data, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Xóa tin tức thành công!')
                this.$router.go(-1)
            }).catch(err => {
                this.$toast.error('Xóa tin tức không thành công!')
            })
        },
        async editNews(id) {
            event.preventDefault()
            this.dataUpdate.news_id = id
            this.dataUpdate.description = this.dataDetail.description
            this.dataUpdate.title = this.dataDetail.title
            this.dataUpdate.noti_status = this.dataDetail.noti_status
            this.dataUpdate.type = 1
            this.dataUpdate.publish_status = 1
            this.isLoading = true
            await this.$axios.put('/news/updateNews', this.dataUpdate, {
                headers: {
                    'x-access-token': this.token
                }
            }).then(res => {
                this.$toast.success('Chỉnh sửa bài viết thành công!')
                this.$router.go(-1)
                this.isLoading = false
            }).catch(err => {
                this.$toast.error('Chỉnh sửa bài viết không thành công!')
                this.isLoading = false
            })
        },
        onReady(editor) {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formatTxt(item) {
            this.dataDetail[item] = this.dataDetail[item].replace(/[<>\[\]]/g, '')
        },
    }
}
</script>
<style lang="css" scoped>
h5 {
    font-family: Roboto;
    color: #515151;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 15px;
}

.wp-formAdd {
    font-family: Roboto;
    box-shadow: 2px 2px 6px 0px #00000026;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 18px 29px 23px 29px;
    color: #515151;
}

input.form-control {
    background-color: #EEEEEE;
    border-radius: 15px;
    height: 68px;
    border: none;
}

input.form-control:focus {
    box-shadow: none;
    border: none;
}

input.form-control::placeholder {
    font-weight: 500;
    color: #929292;
}

button {
    color: #EE3439;
    border: 1px solid #EE3439;
    border-radius: 20px;
    background: none;
    font-size: 18px;
    font-weight: 500;
    width: 160px;
    height: 42px;
    margin-right: 10px;
    margin-top: 15px;
}

button.send {
    margin-right: 0;
    color: #FFFFFF;
    background-color: #EE3439;
    width: 203px;
}

.btn-del {
    margin: 0;
    height: 27px;
    font-size: 14px;
    width: 120px;
    margin-left: 15px;
}

.form-switch .form-check-input:checked {
    background-color: #EE3439;
    border-color: #EE3439;
}

.form-switch .form-check-input:focus {
    box-shadow: none;
}
</style>

<style>
.ql-container {
    height: unset !important;
    min-height: 400px;
    max-height: 1000px;
    overflow-y: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ql-container::-webkit-scrollbar {
    width: 0;
}

.ql-snow .ql-tooltip {
    left: 0 !important;
}

.ql-snow .ql-tooltip input[type=text] {
    color: #515151;
}

.ql-toolbar {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ql-editor.ql-blank::before {
    color: #929292;
    font-size: 20.62px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
}</style>