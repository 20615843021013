<template>
    <div :class="{ 'overlay': isLoading }">
        <div>
            <h5>Tạo tin tức mới</h5>
            <form class="wp-formAdd" @submit="addNews('add')">
                <div class="d-flex align-items-center justify-content-between">
                    <p style="font-size: 20px; font-weight: 500;">Viết tin tức</p>
                    <div class="d-flex">
                        <span style="margin-right: 20px;">Gửi thông báo đến tất cả khách hàng:</span>
                        <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" role="switch" v-model="dataAdd.noti_status">
                        </div>
                    </div>
                </div>
                <b-row class="align-items-center mb-3">
                    <b-col cols="md-2">
                        <p>Hình ảnh hiển thị</p>
                    </b-col>
                    <b-col cols="md-10">
                        <img :src="this.dataImg" v-if="this.dataImg"
                            style="max-width: 177px; border-radius: 10px; margin-right: 20px;"
                            onclick="$('#file_img').click()" @error="handleError">
                        <img src="@/assets/admin/icon/icon_add.png" alt="" onclick="$('#file_img').click()" v-else>
                        <b-form-file class="mt-3 d-none" id="file_img" accept="image/png, image/gif, image/jpeg, image/jpg"
                            ref="fileInput" @change="handleImgChange"></b-form-file>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="md-2">
                        <label for="ttl">Tiêu đề tin tức</label>
                    </b-col>
                    <b-col cols="md-10">
                        <b-input type="text" id="ttl" placeholder="Nhập tiêu đề của tin tức và thông báo"
                            v-model="dataAdd.title" required @input="formatTxt('title')"></b-input>
                    </b-col>
                </b-row>

                <p class="mt-4" style="font-size: 20px; font-weight: 600;">Nội dung tin tức và thông báo</p>

                <ckeditor :editor="editor" v-model="dataAdd.description" :config="editorConfig" @ready="onReady"></ckeditor>

                <div class="text-right">
                    <button @click="$router.go(-1)" :disabled="isLoading">Hủy bỏ</button>
                    <button type="button" @click="addNews('draft')" :disabled="isLoading">Lưu bản nháp</button>
                    <button class="send" type="submit" :disabled="isLoading">Gửi phê duyệt</button>
                </div>
            </form>
        </div>
        <div class="loader" v-if="isLoading"></div>
    </div>
</template>
<script>
import Editor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-classic/build/translations/vi';
import axios from 'axios'
export default {
    data() {
        function uploader(editor) {
            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                return uploadImage(loader);
            };
        }

        function uploadImage(loader) {
            return {
                upload: () => {
                    return new Promise((resolve, reject) => {
                        loader.file.then(async (file) => {
                            try {
                                if (!file) return

                                var data = {
                                    'file': file,
                                    'path': 'news/image/'
                                }

                                await axios.post('https://api.daugiadgv.vn/api/common/uploadFile', data, {
                                    headers: {
                                        'x-access-token': '',
                                        'Content-Type': 'multipart/form-data'
                                    }
                                }).then(res => {
                                    const url = 'https://api.daugiadgv.vn/storage/' + res.data.list_result[0].path_tam
                                    resolve({ default: url })
                                })
                            } catch (error) {
                                reject(error)
                            }
                        })
                    })
                }
            }
        }
        return {
            dataImg: '',
            token: '',
            dataAdd: {
                title: '',
                description: '',
                type: '',
                image: '',
                noti_status: '',
                publish_status: '',
            },
            isLoading: false,
            editor: Editor,
            editorConfig: {
                extraPlugins: [uploader],
                language: 'vi',
                toolbar: {
                    items: [
                        'undo',
                        'redo',
                        '|',
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'strikethrough',
                        'underline',
                        '|',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'imageUpload',
                        'link',
                        '|',
                        'blockQuote',
                        'insertTable',
                        'alignment',
                        '|',
                        'fontFamily',
                        'fontSize',
                        'fontColor',
                        'fontBackgroundColor',
                    ]
                },
            }
        }
    },
    mounted() {
        const storedData = localStorage.getItem('admInfo')
        if (storedData) {
            var info = JSON.parse(storedData)
            this.token = info.accessToken
        }
    },
    methods: {
        async uploadImage(type, file) {
            const formData = {
                'file': file,
                'path': 'news/image/'
            };
            await this.$axios.post('/common/uploadFile', formData, {
                headers: {
                    'x-x-access-token': this.token,
                    'Content-Type': 'multipart/form-data',
                },
            }).then((response) => {
                if (type == 'thumb') {
                    this.dataAdd.image = response.data.list_result[0].path
                }
            }).catch(err => {
                if (err.response.status == 409) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Tài khoản được đăng nhập từ thiết bị khác!')
                    this.$router.push('/loginAdmin')
                } else if (err.response.status == 401) {
                    localStorage.removeItem('admInfo')
                    this.$toast.error('Phiên đăng nhập đã hết hạn!')
                    this.$router.push('/loginAdmin')
                }
            })
        },
        handleImgChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = () => {
                    this.dataImg = reader.result
                    this.uploadImage('thumb', file)
                };
                reader.readAsDataURL(file);
            }
        },
        async addNews(type) {
            event.preventDefault()
            this.dataAdd.type = 1
            if (this.dataAdd.noti_status) {
                this.dataAdd.noti_status = 1
            } else {
                this.dataAdd.noti_status = 0
            }
            if (type == 'add') {
                this.dataAdd.publish_status = 1
                if (this.dataAdd.image == '') {
                    this.$toast.error('Vui lòng thêm đầy đủ thông tin!')
                } else {
                    this.isLoading = true
                    await this.$axios.post('/news/createNews', this.dataAdd, {
                        headers: {
                            'x-access-token': this.token
                        }
                    }).then(res => {
                        this.$toast.success('Tạo mới bài viết thành công!')
                        this.$router.push('/admin/app-news')
                        this.isLoading = false
                    }).catch(err => {
                        this.$toast.error('Tạo mới bài viết không thành công!')
                        this.isLoading = false
                    })
                }
            } else {
                this.dataAdd.publish_status = 0
                this.isLoading = true
                await this.$axios.post('/news/createNews', this.dataAdd, {
                    headers: {
                        'x-access-token': this.token
                    }
                }).then(res => {
                    this.$toast.success('Lưu bản nháp bài viết thành công!')
                    this.$router.push('/admin/draft-news')
                    this.isLoading = false
                }).catch(err => {
                    this.$toast.error('Lưu bản nháp bài viết không thành công!')
                    this.isLoading = false
                })
            }
        },
        onReady(editor) {
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formatTxt(item) {
            this.dataAdd[item] = this.dataAdd[item].replace(/[<>\[\]]/g, '')
        },
    }
}
</script>
<style lang="css" scoped>
h5 {
    font-family: Roboto;
    color: #515151;
    font-size: 22px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: 15px;
}

.wp-formAdd {
    font-family: Roboto;
    box-shadow: 2px 2px 6px 0px #00000026;
    background-color: #FFFFFF;
    border-radius: 15px;
    padding: 18px 29px 23px 29px;
    color: #515151;
}

input.form-control {
    background-color: #EEEEEE;
    border-radius: 15px;
    height: 68px;
    border: none;
}

input.form-control:focus {
    box-shadow: none;
    border: none;
}

input.form-control::placeholder {
    font-weight: 500;
    color: #929292;
}

button {
    color: #EE3439;
    border: 1px solid #EE3439;
    border-radius: 20px;
    background: none;
    font-size: 18px;
    font-weight: 500;
    width: 160px;
    height: 42px;
    margin-right: 10px;
    margin-top: 15px;
}

button.send {
    margin-right: 0;
    color: #FFFFFF;
    background-color: #EE3439;
    width: 203px;
}

.form-switch .form-check-input:checked {
    background-color: #EE3439;
    border-color: #EE3439;
}

.form-switch .form-check-input:focus {
    box-shadow: none;
}
</style>

<style>
.ql-container {
    height: unset !important;
    min-height: 400px;
    max-height: 1000px;
    overflow-y: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.ql-container::-webkit-scrollbar {
    width: 0;
}

.ql-snow .ql-tooltip {
    left: 0 !important;
}

.ql-snow .ql-tooltip input[type=text] {
    color: #515151;
}

.ql-toolbar {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.ql-editor.ql-blank::before {
    color: #929292;
    font-size: 20.62px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
}

.ck.ck-voice-label {
    display: none !important;
}

.ck-content {
    min-height: 400px;
    max-height: 1000px;
    border: 1px solid #ccced1 !important;
}

.ck-content ul {
    padding-left: 2rem;
}</style>